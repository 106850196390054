import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`We`}</a>{` hosted our third bot demo night at Wilson Sonsini in SF.`}</p>
    <p>{`It was an interesting mix of bots for productivity, gifting, entertainment, prayers, local, and travel.`}</p>
    <h2><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2016/09/07155152/0_HLKXUg5hHlZD7EVM.png",
          "alt": null
        }}></img></figure><a parentName="h2" {...{
        "href": "http://www.dashbot.io"
      }}>{`Dashbot`}</a></h2>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` is a bot analytics platform that enables bot developers and enterprises to increase user acquisition, engagement, and monetization through actionable data and tools.`}</p>
    <p>{`In addition to standard metrics like engagement and retention, we have bot specific metrics like conversational analytics, sentiment analysis, and the full chat session transcripts.`}</p>
    <p>{`We also provide tools to take action on the data — like our `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/sdk/pause"
      }}>{`live person take over`}</a>{` of sessions to help lead a user to conversion and increase engagement.`}</p>
    <p>{`You can sign up for free at `}<a parentName="p" {...{
        "href": "http://www.dashbot.io./"
      }}>{`www.dashbot.io.`}</a></p>
    <h2>{`Demos`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.facebook.com/akitabot"
      }}><strong parentName="a">{`Akita Bot`}</strong></a></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/09/07155151/0_-UrI9WGK8j1lI17j.png)
    </figure>
Find and review local businesses…
    <p>{`Rhett returned with his latest bot — Akita. The bot enables users to find local businesses, get more information, and leave reviews. With a little “growth hacking” he increased his user base by 15% at the event! 🙂`}</p>
    <p>{`Rhett has built other bots, including the Black Jack bot, and a bot platform using AWS Lambda functions.`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.tunnel.ai/"
      }}><strong parentName="a">{`Avabot`}</strong></a></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/09/07155151/0_H4noxY4d35IOdOtP.png)
    </figure>
Got SQL?
    <p>{`Luke and Tenizen demoed their Slack bot — Avabot. This is a really interesting bot that enables users to run SQL inside Slack.`}</p>
    <p>{`One of the cool features is the ability to parameterize and save the SQL statements into plain English for others to use. For example, a business analyst could write a sql statement to return the number of sales associates who sold more than a certain dollar amount — i.e. “select count(`}{`*`}{`) from employees where revenues `}{`>`}{` 1000”. When they save it, they can create a plain english phrase with parameters for other users to be able to run — like “top sales over {x}” — then anyone can run the query as “top sales over 1000” directly in Slack.`}</p>
    <p><a parentName="p" {...{
        "href": "http://evabot.ai/"
      }}><strong parentName="a">{`Evabot`}</strong></a></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/09/07155151/0_QO3eoBsw2uWrVnJC.png)
    </figure>
It’s better to give than receive…
    <p>{`Rabi demoed Evabot — a Slack bot that enables people to send real world gifts to friends.`}</p>
    <p>{`It’s fairly easy to use. For example, you can send a beer to a friend. The friend will receive a message and can select the type of beer they want, as well as when they would like it delivered — and then one of Evabot’s “Happiness Agents” will deliver the actual beer.`}</p>
    <p><a parentName="p" {...{
        "href": "http://firefliesapp.com/"
      }}><strong parentName="a">{`Fireflies`}</strong></a></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/09/07155150/0_KifmY5r_u9lW1GL6.png)
    </figure>
Automate tasks and todo’s with your own personal assistant.
    <p>{`Sam and Krish recently relocated to SF and demoed Fireflies — the personal assistant bot. In addition to their Slack and Facebook bots, they have a chrome extension that auto identifies and populates tasks from messenger interactions — it can even save events to Google Calendar.`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.getprayerbot.com/"
      }}><strong parentName="a">{`Prayer Bot`}</strong></a></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/09/07155149/0__A6681PKOw-tSVPP.png)
    </figure>
Sending thoughts and prayers
    <p>{`Vic demoed PrayerBot which enables users to ask for, and send thoughts and prayers. You can also send individual messages to users.`}</p>
    <p>{`It’s not just a bot, but a platform. The target customers are churches — to offer the bot to their members. Churches can quickly add the bot to their Facebook pages.`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.quest.city/"
      }}><strong parentName="a">{`QuestCity`}</strong></a></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/09/07155149/0_Pv_QFBundCXrL8jC.png)
    </figure>
Conversationally guided tours
    <p>{`Arjun demoed Quest City’s tour bot. Users follow along with the bot as they explore different parts of town.`}</p>
    <p>{`One of the current tours is a food tour of Oakland, based on the creator Raquel’s real live tour. The bot attempts to simulate the personality of Raquel as it leads users along tour — pointing out areas of interest and asking questions as well.`}</p>
    <p><a parentName="p" {...{
        "href": "http://getsnaptravel.com/"
      }}><strong parentName="a">{`SnapTravel`}</strong></a></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/09/07155149/0_D2Z6DQnwxcumP4o9.png)
    </figure>
Hotel bookings through conversation
    <p>{`SnapTravel founder, Hussein, was in town and demoed their hotel booking bot.`}</p>
    <p>{`It’s a hybrid between bot and human. The bot asks all the initial questions to narrow down the hotel choice, and a live person reviews the recommendations before sending them to the user.`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.facebook.com/streaktriviabot"
      }}><strong parentName="a">{`StreakTrivia`}</strong></a></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/09/07155148/0_Sm8prPZg2a1dmNGO.png)
    </figure>
And the answer is…
    <p>{`To round out the night on a fun note, we had James and Max of StreakTrivia demo their trivia bot again.`}</p>
    <p>{`It’s a very engaging bot!`}</p>
    <p>{`Every day at 1pm there’s a new trivia game. Users are competing to see who is the last one standing — with the longest “streak.” If you get a question wrong, or don’t answer, you’re out! It’s a lot of fun to play — the suspense between questions, waiting for the results, seeing who’s left, etc — it’s all pretty exciting.`}</p>
    <h2>{`Stay tuned…`}</h2>
    <p>{`Once again we enjoyed seeing familiar faces as well as meeting new people — definitely an exciting space!`}</p>
    <p>{`We’ll host another demo night next month. If you’d like to demo your bot, please `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/contact"
      }}>{`let us know`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      